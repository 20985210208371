import React, { FC } from 'react'
import { Text, Flex } from 'baselift'
import { useI18n, TranslationBundles } from '../../i18n/i18n-context'
import { useTheme } from '../../theme/use-theme'
import { useBreakpoints } from '../../context/breakpoints-context'
import { ArrowRightIcon } from '../../theme/icons'
import { Link } from 'gatsby'
import { ExternalLinks } from '../footer/external-links'
import { WavyHero } from '../shared/wavy-hero'

const translations: TranslationBundles = {
  'en-US': {
    namespace: 'home',
    translations: {
      seo: {
        title: 'Jonathan Madelaine | Web Developer',
        description: 'I build apps with React. I do other things too.',
        pathname: '',
      },
      blurb: 'Welcome to the home of',
      name: 'Jonathan Madelaine',
      job_title: 'Web Developer',
      location: 'Wellington, NZ',
      primary_button: 'Get in touch',
    },
  },
  'ja-JP': {
    namespace: 'home',
    translations: {
      seo: {
        title: 'ジョナサン・マドレーヌ。ウェブ開発者',
        description: '私はReactでアプリを作成しています。私も他のことをします。',
        pathname: '',
      },
      blurb: 'どうぞ上がってください',
      name: 'ジョナサン・マドレーヌ',
      job_title: 'ウェブ開発者',
      location: 'ニュージーランド、ウェリントン',
      primary_button: 'お問い合わせ',
    },
  },
}

export const Home: FC = () => {
  const theme = useTheme()
  const { bp } = useBreakpoints()
  const { t } = useI18n(translations)

  return (
    <>
      <WavyHero>
        <Text element="p">{t('home:blurb')}</Text>
        <Text
          css={bp({
            fontSize: [theme.fontSizes[6], theme.fontSizes[8]],
            fontWeight: 'bold',
            lineHeight: 1.2,
          })}
          element="h1"
        >
          {t('home:name')}
        </Text>
        <Text
          css={bp({
            fontSize: [theme.fontSizes[3], theme.fontSizes[4]],
            marginTop: theme.space('s'),
          })}
          element="p"
        >
          {t('home:job_title')}
          <span aria-hidden="true" css={bp({ display: ['none', 'inline'] })}>
            {' · '}
          </span>
          <br css={bp({ display: ['inline', 'none'] })} />
          {t('home:location')}
        </Text>
        <Link
          aria-label="Contact form"
          css={bp({
            position: 'relative',
            color: 'var(--button-text)',
            padding: theme.space('s', 'm'),
            lineHeight: 1,
            background: 'var(--button-background)',
            borderRadius: '4px',
            marginTop: theme.space('m'),
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'background 0.2s',
            alignSelf: ['stretch', 'start'],
            userSelect: 'none',
            '&:hover': {
              background: 'var(--button-background--hover)',
            },
            '&:focus': {
              background: 'var(--button-background--hover)',
            },
          })}
          to="/#contact"
          onClick={() => {
            if (window !== undefined) {
              const contactNameInput = window.document.getElementById('contact-name')
              if (contactNameInput) {
                contactNameInput.focus()
              }
            }
          }}
        >
          <Text
            css={bp({
              marginRight: [0, '24px'],
              paddingRight: [0, theme.space('s')],
            })}
          >
            {t('home:primary_button')}
          </Text>
          <ArrowRightIcon
            aria-hidden="true"
            css={bp({
              display: ['none', 'inline-block'],
              position: 'absolute',
              right: theme.space('m'),
              transition: 'transform 0.2s',
              'a:hover &': {
                transform: 'rotate(90deg)',
              },
            })}
          />
        </Link>
      </WavyHero>
      <Flex
        css={bp({
          marginTop: '-120px',
          marginBottom: '80px',
          justifyContent: 'center',
          width: '100%',
        })}
      >
        <ExternalLinks alignMain="center" showFirst={3} />
      </Flex>
    </>
  )
}
