import React, { FC } from 'react'
import { Icon } from 'baselift'

export const Wave: FC = props => (
  <Icon
    viewBox="0 0 4096 160"
    pathDef={[
      'm 2270.9896,70.947964 c 133.7046,-18.668866 171.5263,18.055904 255.5736,12.525128 139.8812,-9.204954 209.5482,-36.332509 309.78,-53.414218 132.9895,-22.6643459 278.3439,-10.726651 407.6396,25.393397 155.8606,43.54122 298.5582,16.405596 436.9436,-10.674096 C 3861.5529,9.4326055 3962.368,32.52297 4096,24 V 0 H 0 v 140 c 205.43317,1.74726 257.41288,-25.951 352.11775,-28.27208 147.91414,-3.62515 282.01714,61.76077 461.42641,39.52334 136.11634,-16.87135 189.72384,-53.066028 324.37964,-44.52334 135.7535,8.61232 200.6037,42.39739 328.7037,44.34315 119.32,1.8124 172.6027,-52.15456 315.1863,-53.769548 100.5889,-1.139333 133.7643,39.694108 247.3075,33.798988 114.9661,-5.96899 152.6493,-47.695106 241.8683,-60.152546 z',
    ]}
    {...props}
  />
)
