import React, { FC, useState, FormEvent, useEffect } from 'react'
import { useI18n, TranslationBundles } from '../../i18n/i18n-context'
import { useTheme } from '../../theme/use-theme'
import { PageTitle } from '../shared/page-title'
import { TextField } from './text-field'
import { Button, Stack, Text, Flex, Box } from 'baselift'
import { TextArea } from './text-area'
import AWS from 'aws-sdk'
import { ErrorIcon } from '../../theme/icons'
import Recaptcha from 'react-google-recaptcha'
import { useValidation } from 'react-use-validation'
import { useDarkMode } from '../../context/dark-mode-context'
import { getEnvVar } from '../../env'

interface IFormErrors {
  name?: string
  email?: string
  message?: string
  captcha?: string
}

const translations: TranslationBundles = {
  'en-US': {
    namespace: 'contact',
    translations: {
      title: 'Contact',
      name_label: 'Name',
      name_error: 'Please enter your name',
      email_label: 'Email',
      email_error: 'Please enter a valid email',
      message_label: 'Message',
      message_error: 'Please enter a message',
      captcha_error: "Please confirm you're not a robot",
      submit_button: 'Send',
      submit_button_sending: 'Sending...',
      network_error: 'There was a problem sending the message.',
      message_sent: 'Message sent!',
      thanks_for_the_message: 'Thanks for getting in touch.',
    },
  },
  'ja-JP': {
    namespace: 'contact',
    translations: {
      title: 'お問い合わせ',
      name_label: '名前',
      name_error: '名前を入力してください',
      email_label: '電子メールアドレス',
      email_error: '電子メールアドレスを入力してください',
      message_label: '通信',
      message_error: '通信を入力してください',
      captcha_error: 'おロボットでないことを確認してください',
      submit_button: '送信',
      submit_button_sending: '送信し',
      network_error: 'ごめんなさい。通信の送信中に問題が発生しました',
      message_sent: '通信が送信される',
      thanks_for_the_message: 'ありがとうございました',
    },
  },
}

const sendMessage = async (
  name: string,
  email: string,
  message: string,
  recaptcha: string
): Promise<boolean> => {
  return new Promise((resolve, _) => {
    const accessKeyId = getEnvVar('GATSBY_AWS_LAMBDA_ACCESS_KEY_ID')
    const secretAccessKey = getEnvVar('GATSBY_AWS_LAMBDA_SECRET_ACCESS_KEY')

    if (accessKeyId && secretAccessKey) {
      const lambda = new AWS.Lambda({
        region: 'eu-west-1',
        credentials: {
          accessKeyId,
          secretAccessKey,
        },
      })

      const params = {
        FunctionName: 'forwardContactFormToEmail',
        Payload: JSON.stringify({
          name,
          email,
          message,
          recaptcha,
        }),
      }

      lambda.invoke(params, (err, __) => {
        if (err) {
          resolve(false)
        } else {
          resolve(true)
        }
      })
    } else {
      resolve(false)
    }
  })
}

export const Contact: FC = () => {
  const theme = useTheme()
  const { t } = useI18n(translations)
  const { isDarkMode } = useDarkMode()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [formErrors, setFormErrors] = useState<IFormErrors>({})
  const [isNetworkError, setIsNetworkError] = useState(false)
  const [isMessageSent, setIsMessageSent] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [isCaptureVisible, setIsCaptureVisible] = useState(false)

  const { invalid, validate } = useValidation({
    nameEntered: [name, n => Boolean(n)],
    emailEntered: [email, e => /.+@.+\..+/.test(e)],
    messageEntered: [message, m => Boolean(m)],
    captchaVerified: [captcha, c => Boolean(c)],
  })

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (isSending || !validate()) {
      return
    }

    setIsSending(true)

    const success = await sendMessage(name, email, message, captcha)

    setIsSending(false)

    if (success) {
      setIsNetworkError(false)
      setIsMessageSent(true)
    } else {
      setIsNetworkError(true)
    }
  }

  useEffect(() => {
    if (name && email && message) {
      setIsCaptureVisible(true)
    }
  }, [name, email, message])

  return (
    <section
      aria-labelledby={`${t('contact:title')}-title`}
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <PageTitle id={`${t('contact:title')}-title`}>{t('contact:title')}</PageTitle>
      {isMessageSent ? (
        <>
          <Text
            role="alert"
            element="h3"
            css={{
              fontSize: theme.fontSizes[5],
            }}
          >
            {t('contact:message_sent')}
          </Text>
          <Text role="alert" element="p">
            {t('contact:thanks_for_the_message')}
          </Text>
        </>
      ) : (
        <form
          aria-label="Contact"
          css={{
            maxWidth: theme.size(11),
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
          noValidate={true}
          onSubmit={onSubmit}
          autoComplete="off"
        >
          <Stack direction="column" space={theme.space('m')} stretch={true}>
            <TextField
              aria-invalid={!!formErrors.name}
              autoComplete="nope"
              data-lpignore="true"
              label={t('contact:name_label')}
              error={invalid.nameEntered ? t('contact:name_error') : undefined}
              required={true}
              id="contact-name"
              value={name}
              onChange={e => {
                if (invalid.nameEntered) {
                  validate('nameEntered', e.target.value)
                }
                setName(e.target.value)
              }}
              onBlur={e => validate('nameEntered', e.target.value)}
            />
            <TextField
              aria-invalid={!!formErrors.email}
              autoComplete="nope"
              data-lpignore="true"
              label={t('contact:email_label')}
              error={invalid.emailEntered ? t('contact:email_error') : undefined}
              required={true}
              id="contact-email"
              type="email"
              value={email}
              onChange={e => {
                if (invalid.emailEntered) {
                  validate('emailEntered', e.target.value)
                }
                setEmail(e.target.value)
              }}
              onBlur={e => validate('emailEntered', e.target.value)}
            />
            <TextArea
              aria-invalid={!!formErrors.message}
              autoComplete="nope"
              data-lpignore="true"
              label={t('contact:message_label')}
              error={invalid.messageEntered ? t('contact:message_error') : undefined}
              required={true}
              id="contact-message"
              value={message}
              onChange={e => {
                if (invalid.messageEntered) {
                  validate('messageEntered', e.target.value)
                }
                setMessage(e.target.value)
              }}
              onBlur={e => validate('messageEntered', e.target.value)}
            />
            {isCaptureVisible && (
              <Box>
                <Recaptcha
                  theme={isDarkMode ? 'dark' : 'light'}
                  sitekey={getEnvVar('GATSBY_RECAPTCHA_SITE_KEY') || ''}
                  onChange={v => {
                    validate('captchaVerified', v || '')
                    setCaptcha(v || '')
                  }}
                />
                {invalid.captchaVerified && (
                  <Text
                    css={{
                      color: 'var(--danger)',
                      fontSize: theme.fontSizes[2],
                    }}
                  >
                    {t('contact:captcha_error')}
                  </Text>
                )}
              </Box>
            )}
            {isNetworkError && !isSending && (
              <Stack
                css={{
                  background: 'var(--contact-network-error-background)',
                  borderRadius: '4px',
                  transition: 'background 0.5s ease-in-out',
                }}
                alignCross="center"
              >
                <Flex
                  css={{
                    alignSelf: 'stretch',
                    alignItems: 'center',
                    padding: theme.space('s'),
                    background: 'var(--danger)',
                    borderTopLeftRadius: '4px',
                    borderBottomLeftRadius: '4px',
                  }}
                >
                  <ErrorIcon
                    aria-hidden="true"
                    css={{
                      fill: 'var(--button-text)',
                    }}
                  />
                </Flex>
                <Text
                  role="alert"
                  id="send-message-error"
                  css={{
                    padding: theme.space('s'),
                  }}
                >
                  {t('contact:network_error')}
                </Text>
              </Stack>
            )}
            <Button
              aria-label="Send message"
              aria-invalid={!!Object.keys(formErrors).length}
              disabled={isSending}
              css={{
                padding: theme.space('s', 'm'),
                lineHeight: 1,
                color: 'var(--button-text)',
                background: isSending
                  ? 'var(--button-background--disabled)'
                  : 'var(--button-background)',
                fontWeight: 'bold',
                borderRadius: '4px',
                transition: 'background 0.2s, color 0.2s',
                userSelect: 'none',
                '&:hover': {
                  background: isSending
                    ? 'var(--button-background--disabled)'
                    : 'var(--button-background--hover)',
                },
                '&:focus': {
                  background: 'var(--button-background--hover)',
                },
                '&:active': {
                  background: 'var(--button-background)',
                },
              }}
              type="submit"
            >
              {t(isSending ? 'contact:submit_button_sending' : 'contact:submit_button')}
            </Button>
          </Stack>
        </form>
      )}
    </section>
  )
}
