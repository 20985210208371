const productionEnv = {
  GATSBY_AWS_LAMBDA_ACCESS_KEY_ID: 'AKIAVNUHH4MZA37K7QRY',
  GATSBY_AWS_LAMBDA_SECRET_ACCESS_KEY: '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',
  GATSBY_RECAPTCHA_SITE_KEY: '6Les2bwZAAAAABT0jVHk1AdMZjqhlXVismOHCIuh',
}

export const getEnvVar = (name: keyof typeof productionEnv) => {
  const envVar = process.env[name]

  if (process.env.NODE_ENV === 'development' && !envVar) {
    throw new Error(
      `You must provide an environment variable for '${name}' in a '.env' file when running the development build`
    )
  }
  return envVar ?? productionEnv[name]
}
