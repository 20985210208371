import React, { FC } from 'react'
import { Flex } from 'baselift'
import { useBreakpoints } from '../../context/breakpoints-context'
import { useTheme } from '../../theme/use-theme'
import { Wave } from '../../theme/graphics'
import { Content } from './content'

export const WavyHero: FC = ({ children, ...other }) => {
  const theme = useTheme()
  const { bp } = useBreakpoints()

  return (
    <Flex
      css={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'var(--background2)',
        transition: 'background 0.5s ease-in-out',
        minHeight: '25vh',
        // marginBottom for fancy line as it is positioned absolutely
        marginBottom: '160px',
      }}
      {...other}
    >
      <Content
        css={bp({
          maxWidth: theme.size(11),
          marginTop: ['auto', 'auto'],
          marginBottom: ['auto', 'auto'],
        })}
      >
        <Flex
          css={{
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {children}
        </Flex>
      </Content>
      <Flex css={{ alignSelf: 'stretch', flexDirection: 'column' }}>
        <Flex
          css={{
            justifyContent: 'center',
            overflow: 'hidden',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <Wave
            aria-hidden="true"
            css={{
              fill: 'var(--background2)',
              transition: 'fill 0.5s ease-in-out',
              // marginBottom prevents dark blurred line along edge so fade is nice
              marginBottom: '-4px',
              flex: '0 0 auto',
              height: '160px',
              width: '4096px',
              '@media (min-width: 4096px)': {
                height: 'auto',
                width: '100%',
              },
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
