import React, { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Flex } from 'baselift'
import { useI18n, TranslationBundles } from '../../i18n/i18n-context'
import { useTheme } from '../../theme/use-theme'
import { useBreakpoints } from '../../context/breakpoints-context'
import { PageTitle } from '../shared/page-title'
import Img from 'gatsby-image'

const translations: TranslationBundles = {
  'en-US': {
    namespace: 'about',
    translations: {
      title: 'About',
      profile_img_alt: 'My profile photo',
    },
  },
}

export const About: FC = () => {
  const theme = useTheme()
  const { bp } = useBreakpoints()
  const { t } = useI18n(translations)

  const {
    contentfulJmPage: {
      body: {
        childMarkdownRemark: { html },
      },
    },
    profilePhoto,
  } = useStaticQuery<any>(
    graphql`
      query AboutPage {
        contentfulJmPage(title: { eq: "About" }) {
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
        profilePhoto: contentfulImage(title: { eq: "Profile Photo" }) {
          source {
            fluid(maxWidth: 256) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `
  )

  const image = (
    <Flex
      css={bp({
        flex: '0 0 auto',
        width: theme.size(4),
        height: theme.size(4),
        borderRadius: '50%',
      })}
    >
      {profilePhoto && (
        <Img
          alt={t('about:profile_img_alt')}
          css={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
          }}
          fluid={profilePhoto.source.fluid}
        />
      )}
    </Flex>
  )

  return (
    <section
      aria-labelledby={`${t('about:title')}-title`}
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <PageTitle id={`${t('about:title')}-title`}>{t('about:title')}</PageTitle>
      {image}
      <Flex
        css={bp({
          marginTop: theme.space('m'),
          position: 'relative',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: theme.size(11),
          '& p': {
            fontWeight: 300,
            marginBottom: [theme.space('xs'), theme.space('s')],
          },
          '& h2': {
            position: 'relative',
            fontSize: [theme.fontSizes[4], theme.fontSizes[5]],
            marginTop: [theme.space('s'), theme.space('m')],
            marginBottom: [theme.space('xs'), theme.space('s')],
          },
          '& a': {
            background: 'var(--anchor-text-background)',
            textDecoration: 'none',
            borderBottom: '1px solid var(--anchor-text-border)',
            padding: '2px 4px',
            borderRadius: '1px',
            '&:hover': {
              background: 'var(--anchor-text-background--hover)',
              borderBottom: '1px solid var(--anchor-text-border--hover)',
            },
            '&:focus': {
              background: 'var(--anchor-text-background--hover)',
              borderBottom: '1px solid var(--anchor-text-border--hover)',
            },
          },
        })}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </section>
  )
}
