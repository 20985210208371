import React, { FC } from 'react'
import { Text } from 'baselift'
import { useTheme } from '../../theme/use-theme'
import { useBreakpoints } from '../../context/breakpoints-context'

export const PageTitle: FC<{ id?: string }> = ({ children, ...other }) => {
  const theme = useTheme()
  const { bp } = useBreakpoints()

  return (
    <Text
      element="h1"
      css={bp({
        fontSize: [theme.fontSizes[6], theme.fontSizes[7]],
        marginBottom: [theme.space('m'), theme.space('l')],
      })}
      {...other}
    >
      {children}
    </Text>
  )
}
