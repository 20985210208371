import React, { FC } from 'react'
import { Seo } from '../components/shared/seo'
import { PageProps } from 'gatsby'
import { useTheme } from '../theme/use-theme'
import { useBreakpoints } from '../context/breakpoints-context'
import { About } from '../components/about/about'
import { useNav } from '../context/nav-context'
import { Contact } from '../components/contact/contact'
import { Home } from '../components/home/home'
import { Content } from '../components/shared/content'
import { Box } from 'baselift'
import { useI18n } from '../i18n/i18n-context'

const HomePage: FC<PageProps> = () => {
  const { t } = useI18n()
  const theme = useTheme()
  const { bp } = useBreakpoints()

  const { links } = useNav()

  return (
    <>
      <Seo
        title={t('home:seo.title')}
        pathname={t('home:seo.pathname')}
        description={t('home:seo.description')}
      />
      <Home />
      <Content>
        <Anchor hash={links[1].hash} />
        <About />
        <Box
          css={bp({
            marginTop: [theme.space('l'), theme.space('xl')],
          })}
        />
        <Anchor hash={links[2].hash} />
        <Contact />
      </Content>
    </>
  )
}

export default HomePage

interface IAnchorProps {
  hash?: string
}

const Anchor: FC<IAnchorProps> = ({ hash, ...other }) => {
  const theme = useTheme()
  const { bp } = useBreakpoints()

  return hash ? (
    <div
      aria-hidden="true"
      id={hash}
      tabIndex={-1}
      css={bp({
        position: 'relative',
        top: [
          `calc(-${theme.size(1)} - ${theme.space('m')})`,
          `calc(-${theme.size(1)} - ${theme.space('l')})`,
        ],
        height: 0,
        padding: 0,
        margin: 0,
      })}
      {...other}
    />
  ) : null
}
