import React, { FC, ComponentProps } from 'react'
import { useTheme } from '../../theme/use-theme'
import { Text, Box, Label, TextField as BaseTextField } from 'baselift'

interface IFormFieldProps {
  label: string
  error?: string
  id: string
  required?: boolean
}

export const TextField: FC<IFormFieldProps & ComponentProps<typeof BaseTextField>> = ({
  label,
  error,
  id,
  required,
  className,
  ...other
}) => {
  const theme = useTheme()

  return (
    <Box className={className}>
      <Label
        css={{
          fontWeight: 'bold',
          color: 'inherit',
        }}
        htmlFor={id}
      >
        {label}
        {required && <span aria-hidden="true">*</span>}
      </Label>
      <BaseTextField
        aria-required={required}
        css={{
          border: `1px solid ${error ? 'var(--danger)' : 'var(--input-border)'}`,
          borderRadius: `4px`,
          padding: theme.space('xs'),
          width: '100%',
          outline: '0',
          '&:hover': {
            border: `1px solid ${error ? 'var(--danger)' : 'var(--input-border--hover)'}`,
          },
          '&:focus': {
            border: `1px solid ${error ? 'var(--danger)' : 'var(--input-border--hover)'}`,
            boxShadow: `0 0 0 1px ${error ? 'var(--danger)' : 'var(--input-border--hover)'}`,
          },
        }}
        id={id}
        required={required}
        {...other}
      />
      {error && (
        <Text
          css={{
            color: 'var(--danger)',
            fontSize: theme.fontSizes[2],
          }}
        >
          {error}
        </Text>
      )}
    </Box>
  )
}
